import React from 'react'
import { hydrateRoot } from 'react-dom/client'

import VdpSSR from './views/VdpSSR'
import { VdpProvider } from '../configs/vdp-view/state.manager'
import { I18nextProvider, i18nConfig } from '../internationalization/src'

import '@sm360/inventory-vdp-360/dist/views/vdp/vdp.view.css'

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const { initialData } = preloadedState

hydrateRoot(
    document.getElementById('widget-inventory-vdp-360'),
    <I18nextProvider i18n={i18nConfig}>
        <VdpProvider initialData={initialData}>
            <VdpSSR />
        </VdpProvider>
    </I18nextProvider>
)

if (module.hot) {
    module.hot.accept()
}
